<template>
    <div class="homebot">
      <!-- <div class="top">
        <div class="top-left">
          <ul>
            <li v-for="(item, index) in routerList"  :key="index">
              <span @click="fun(item.url)">{{ item.name }}</span>
            </li>
            <div class="top-right" @click="goCase()" style="cursor:pointer">
              <span>赣ICP备2023002526号-1 </span>
            </div>
          </ul>
        </div>
        <div class="top-right">
          <img src="../assets/img/weixin_back.png">
          <img src="../assets/img/zhifubao_back.png">
        </div>
      </div> -->
      <div class="bg-mask"></div>
      <div class="footer-content">
        <img
          class="placeholder-img"
          src="../assets/img/bg3.png"
          alt=""
          srcset=""
        />
        <div class="platform-info">
          <div class="item">
            <span>{{ info.openbox_num }}</span>
            <span>已开启盲盒总数</span>
          </div>
          <div class="item center">
            <span>{{ info.player_num }}</span>
            <span>已注册人数</span>
          </div>
          <div class="item">
            <span>{{ info.online_num }}</span>
            <span>在线人数</span>
          </div>
        </div>
        <ul class="list">
          <li
            v-for="(item, index) in routerList"
            @click="fun(item.url)"
            :key="`${item.url}-${index}`"
          >
            <span class="link-name">{{ item.name }}</span>
            <span v-if="index + 1 != routerList.length">|</span>
          </li>
        </ul>
        <div class="copyright">
          <span class="ba-right" @click="goCase()"
            >备案号：赣ICP备2023002526号-1</span
          >
          <div class="top-right">
            <img src="../assets/img/weixin.png" />
            <img src="../assets/img/zhifubao.png" />
          </div>
        </div>
        <ul class="list">
          <li v-for="(item, index) in imgLinkList" :key="item.img">
            <img :src="item.img" alt="" srcset="" style="width: 100px" />
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        routerList: [
          { name: "关于我们", url: "Abouts" },
          { name: "用户协议", url: "Agreement" },
          { name: "隐私条款", url: "Privacy" },
          { name: "协议与条款", url: "Clause" },
          { name: "常见问题", url: "Doubt" },
          { name: "营业执照", url: "Doubt" },
        ],
        urlList: [],
        imgLinkList: [
          {
            img: require("../assets/img/star4.png"),
            link: "",
          },
          {
            img: require("../assets/img/BCPicon.png"),
            link: "",
          },
          {
            img: require("../assets/img/crz850.png"),
            link: "",
          },
          {
            img: require("../assets/img/crz315003.png"),
            link: "",
          },
          {
            img: require("../assets/img/sign.png"),
            link: "",
          },
        ],
        info: {
          player_num: 0,
          online_num: 0,
          openbox_num: 0,
        },
      };
    },
    created() {
      this.$axios
        .post("/index/Login/logininfo", this.$qs.stringify({}))
        .then((res) => {
          if (res.data.status) {
            this.info = res.data.data;
          }
        });
    },
    methods: {
      fun(url) {
        this.$router.push({
          path: `/${url}`,
        });
      },
      goUrl(url) {
        window.open(url, "_blank");
      },
      goCase() {
        window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .homebot {
    padding: 24px;
    height: 400px;
    position: relative;
    .bg-mask {
      background-image: url("http://192.168.31.51:8081/img/mainbg.39b83680.png");
      filter: blur(10px);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .copyright {
      max-width: 800px;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ba-right {
        color: #878787;
        font-size: 12px;
        cursor: pointer;
      }
      .top-right {
        margin-top: 20px;
      }
    }
  
    .footer-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      color: #fff;
      .placeholder-img {
        width: 100%;
      }
      .list {
        max-width: 800px;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        img {
          cursor: pointer;
          margin-bottom: 10px;
        }
        li {
          margin-right: 10px;
          font-size: 12px;
  
          .link-name {
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .platform-info {
        max-width: 800px;
        margin: 40px auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          height: 100px;
          font-size: 14px;
          & > span:nth-child(1) {
            font-weight: bold;
            font-size: 16px;
          }
        }
        .center {
          position: relative;
          &::before {
            display: block;
            content: "";
            width: 1px;
            height: 100px;
            position: absolute;
            left: 0;
            top: 0;
            background-image: -webkit-gradient(
              linear,
              left top,
              left bottom,
              from(hsla(0, 0%, 100%, 0.1)),
              color-stop(#ccc),
              to(hsla(0, 0%, 100%, 0.1))
            );
            background-image: -webkit-linear-gradient(
              hsla(0, 0%, 100%, 0.1),
              #ccc,
              hsla(0, 0%, 100%, 0.1)
            );
            background-image: -o-linear-gradient(
              hsla(0, 0%, 100%, 0.1),
              #ccc,
              hsla(0, 0%, 100%, 0.1)
            );
            background-image: linear-gradient(
              hsla(0, 0%, 100%, 0.1),
              #ccc,
              hsla(0, 0%, 100%, 0.1)
            );
          }
          &::after {
            display: block;
            content: "";
            width: 1px;
            height: 100px;
            position: absolute;
            right: 0;
            top: 0;
            background-image: -webkit-gradient(
              linear,
              left top,
              left bottom,
              from(hsla(0, 0%, 100%, 0.1)),
              color-stop(#ccc),
              to(hsla(0, 0%, 100%, 0.1))
            );
            background-image: -webkit-linear-gradient(
              hsla(0, 0%, 100%, 0.1),
              #ccc,
              hsla(0, 0%, 100%, 0.1)
            );
            background-image: -o-linear-gradient(
              hsla(0, 0%, 100%, 0.1),
              #ccc,
              hsla(0, 0%, 100%, 0.1)
            );
            background-image: linear-gradient(
              hsla(0, 0%, 100%, 0.1),
              #ccc,
              hsla(0, 0%, 100%, 0.1)
            );
          }
        }
      }
    }
  
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #848492;
      .top-left {
        ul {
          display: flex;
          flex-wrap: wrap;
  
          li {
            margin-top: 10px;
            margin-right: 32px;
            span {
              white-space: nowrap;
              display: inline-block;
            }
          }
          li:hover {
            cursor: pointer;
          }
        }
      }
      .top-right {
        margin-top: 10px;
  
        img {
          margin-right: 10px;
        }
      }
    }
    .bot {
      margin-top: 24px;
      display: flex;
      font-size: 14px;
      color: #515260;
      overflow-y: hidden;
      .bot-title {
        margin-right: 12px;
      }
      ul {
        display: flex;
        li {
          margin-right: 24px;
        }
        li:hover {
          cursor: pointer;
          text-decoration: underline;
          color: #e9b10e;
        }
      }
    }
  }
  </style>
  