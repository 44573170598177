  export function battle() {
      // const wsuri = "wss://fzxbwl.com/ws/";
      // const wsuri = "wss://cz-chunxinhuanbao.com/ws/";
      // const wsuri = "ws://192.168.101.12:7654/";
      // const wsuri = "ws://192.168.149.1:7654/";
      // const wsuri = "wss://t.ym4954.armin.cc/ws/";
      const wsuri = "wss://www.5199cs2.com/ws/";
      return wsuri;
  }
  export function openBox() {
      // const wsuri = "wss://fzxbwl.com/wss/";
      // const wsuri = "wss://cz-chunxinhuanbao.com/wss/";
      // const wsuri = "ws://192.168.101.12:7655/";
      // const wsuri = "ws://192.168.149.1:7655/";
      // const wsuri = "wss://t.ym4954.armin.cc/wss/";
      const wsuri = "wss://www.5199cs2.com/ws/";
      return wsuri;
  }