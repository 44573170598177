import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import scroll from 'vue-seamless-scroll'
import 'element-ui/lib/theme-chalk/index.css';
const bus = new Vue();
Vue.prototype.$bus = bus

import Axios from 'axios'
Vue.config.productionTip = false
    // step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
    // 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = Axios

// step3：使每次请求都会带一个 /api 前缀  // https://518csgo.com/   
// Axios.defaults.baseURL = 'https://518csgo.com/'    // '/api'   https://ahyltt.com.com/
Axios.defaults.baseURL = '/'
Axios.defaults.timeout = 60000;
Axios.defaults.retry = 3;

Axios.interceptors.request.use((config) => {
    const token = store.state.token
    if (token) {
        config.headers['token'] = token
    }
    return config
}, config => {
    return Promise.reject(config)
})

Axios.interceptors.response.use((response) => {
    const code = response.data.code || response.data.status
    if (![1, 0, 5].includes(code)) {
        if (code == 401) {
            store.commit("getLogin", true);
        }
        return Promise.reject(response)
    }
    return response
}, (response) => {
    console.log(response)
    return Promise.reject(response)
})

import qs from 'qs';
// Vue.prototype.$ajax = axios // 把axios换成$ajax变量
Vue.prototype.$qs = qs;

//修改网页标题
Vue.directive('title', {
    inserted: function(el, binding) {
        document.title = el.dataset.title
    }
})


Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.use(scroll)

new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')