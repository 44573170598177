<template>
    <div class="task-center">
      <myinform></myinform>
      <myslide style="margin-top:20px"></myslide>
      <!-- my task center -->
      <LrfTabs :active.sync="activeName" :list="tabs" />
  
      <keep-alive>
        <transition name="el-zoom-in-center">
          <Component :is="activeName"></Component>
        </transition>
      </keep-alive>
    </div>
  </template>
  
  <script>
  import myslide from "@/components/my_slide1.vue";
import myinform from "@/components/my_inform.vue";
import SignDay from "./components/Sign-day.vue";
import BindBoxTask from "./components/bind-box-task.vue";
import CombatTask from "./components/combat-task.vue";
import PrepaidRecharge from "./components/prepaid-recharge.vue";
import LrfTabs from "./components/lrf-tabs.vue";
  export default {
    components: {
      myslide,
      myinform,
      SignDay,
      BindBoxTask,
      CombatTask,
      PrepaidRecharge,
    //   PasswordPacket,
      LrfTabs,
    },
    data() {
      return {
        activeName: "SignDay",
        tabs: [
          {
            componentName: "SignDay",
            name: "每日签到",
          },
          {
            componentName: "BindBoxTask",
            name: "盲盒任务",
          },
          {
            componentName: "CombatTask",
            name: "对战任务",
          },
          {
            componentName: "PrepaidRecharge",
            name: "充值补给",
          },
   
        ],
      };
    },
  };
  </script>
  <style scoped>
.task-center{
    min-height:100vh;
    background: #1e1e24;
}
</style>
  <!-- <style lang="less" scoped src="./task-center.less"></style> -->
  