<template>
    <div class="bind-box-task">
      <div class="box-item" v-for="(item, index) in list" :key="index">
        <div class="left">
          <header>
            <img src="@/assets/img/box.png" alt="" srcset="" />
            <span>每日对战</span>
          </header>
          <div class="use">
            每日对战消耗{{ item.lim}}金币（<span style="color: #e9b108">{{
              item.ls
            }}</span
            >/{{ item.lim }}）
          </div>
          <div class="jiangli">
            任务奖励： 保底{{ item.min }}
            <img src="@/assets/img/money.png" alt="" srcset="" />
            最高{{ item.max }}
            <img src="@/assets/img/money.png" alt="" srcset="" />
          </div>
        </div>
        <div class="right" @click="consumeRecharge(item)">
          {{ item.ls > item.lim ? "立即领取" : "立即前往" }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        list: [],
      };
    },
    created() {
      this.getBindBoxTaskList();
    },
    methods: {
      async getBindBoxTaskList() {
        const params = {
          player_id: localStorage.getItem("id"),
        };
        const { data } = await this.$axios.post(
          "/index/Activity/battle_reward_info",
          this.$qs.stringify(params)
        );
        this.list = data.data.info;
      },
      async consumeRecharge(item) {
        if (item.ls > item.lim) {
          const params = {
            id: item.id,
            player_id: localStorage.getItem("id"),
          };
          const { data } = await this.$axios.post(
            "/index/Activity/battle_task",
            this.$qs.stringify(params)
          );
          if (data.status) {
            this.$message.success("领取成功");
          } else {
            this.$message.error(data.msg);
          }
        } else {
          this.$router.push({
            path: "/Lucky",
          });
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .bind-box-task {
    color: #fff;
    padding: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    .box-item {
      background-color: #343a40;
      padding: 10px;
      display: flex;
      max-width: 500px;
      align-items: center;
      border-radius: 5px;
      .left {
        flex: 1;
        header {
          display: flex;
          align-items: center;
          font-size: 15px;
          img {
            width: 30px;
            margin-right: 7px;
          }
        }
        .use {
          margin: 10px 0;
          font-size: 16px;
        }
        .jiangli {
          display: flex;
          align-items: center;
          font-size: 12px;
          img {
            width: 15px;
            margin: 0 5px;
          }
        }
      }
  
      .right {
        width: 60px;
        font-size: 14px;
        color: #e9b10e;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  </style>
  