<template>
    <div class="lrf-tabs">
        <div class="tabs">
            <div
                class="tabs_item"
                @click="activeName = item.componentName"
                :class="{ active_item: activeName == item.componentName }"
                v-for="item in list"
                :key="item.componentName"
            >
                {{ item.name }}
            </div>
        </div>
    </div>
</template>
  
  <script>
export default {
    props: {
        active: {
            type: String
        },
        list: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    created() {
        if (!this.active && this.list.length) {
            this.active = this.list[0].componentName
        }
    },
    computed: {
        activeName: {
            get() {
                return this.active
            },
            set(name) {
                this.$emit('update:active', name)
            }
        }
    }
}
</script>
  
  <style lang="less" scoped>
.lrf-tabs {
    color: #fff;
    .tabs {
        height: 20px;
        line-height: 20px;
        display: flex;
        padding: 20px 0;
        align-items: center;
        overflow-x: auto;
        &_item {
            flex: 1;
            text-align: center;
            min-width: 100px;
            max-width: 150px;
            color: #c3c3e2;
            border-right: 2px solid #e9b10e;
            cursor: pointer;
            user-select: none;
        }
        &_item:last-child {
            border-right: none;
        }
        .active_item {
            color: #e9b10e;
            font-weight: bold;
        }
    }
}
</style>
  