<template>
  <div
    class="base-slider-pc"
    :class="{
      'small-screen': small_screen,
    }"
    :style="{
      right: getSliderRight,
    }"
  >
    <div class="item new_container" @click="newManChest">
      <div class="new"></div>
      <span>新人宝箱</span>
    </div>

    <div class="item" @click="gotTo('/task-center')">
      <img
        src="@/assets/img/qiandao.webp"
        style="width: 30px; height: auto"
      />
      <span>签到</span>
    </div>
    <div class="item lucky-money_container" @click="$emit('hongbao')">
      <div class="lucky-money"></div>
      <span>红包</span>
    </div>
    <div class="item chat-group_container" @click="qqGroup()">
      <div class="chat-group"></div>
      <span>群聊</span>
    </div>
    <div class="item helper_container" @click="gotTo('/Payment')">
      <div class="helper"></div>
      <span>充值</span>
    </div>
    <!-- <div class="item back_container" @click="$emit('retrieveTheAssistant')">
      <div class="back"></div>
      <span>取回助手</span>
    </div> -->

    <div class="item back-top_container" @click="backTop">
      <img
        class="back-top"
        src="@/assets/img/zhiding.webp"
      />
      <span>顶置</span>
    </div>
    <div class="switch" @click="changeSlider">
      <i :class="[status ? 'el-icon-arrow-left' : 'el-icon-arrow-right']"></i>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      // 小屏
      small_screen: false,
      // 状态 0展开 1折叠
      status: 0,
    };
  },
  computed: {
    getSliderRight() {
      if (this.small_screen) {
        if (this.status) {
          return "-65px !important";
        } else {
          return "-10px !important";
        }
      } else {
        if (this.status) {
          return "-70px !important";
        } else {
          return "0px !important";
        }
      }
    },
  },
  created() {
    this.checkScreen();
    window.addEventListener("resize", () => {
      this.checkScreen();
    });
  },
    methods: {
        newManChest() {
            this.$router.push({path:'/Openbox',query:{box_id:62}})
        },
    changeSlider() {
      this.status = this.status ? 0 : 1;
    },
    checkScreen() {
      if (window.innerWidth < 600) {
        this.small_screen = true;
        return;
      }
      this.small_screen = false;
    },
    backTop() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    },
    gotTo(url) {
      this.$router.push({path:url});
    },
    qqGroup() {
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=XIbmiZcXF80D5U_i-OB5y_MXLI6diGaj&jump_from=webapi&authKey=Jk98JDK3q0Tt1Qy28tLuI2wJpgtF1qQIFPwta1YKryEbbl9zQOUPk5Kk0lm+vw8k"
      );
    },
    openHongbao() {
      this.$emit("openHongbao");
    },
  },
};
</script>
  
  <style lang="less" scoped>
div {
  -webkit-tab-highlight-color: transparent;
}
.small-screen {
  transform: scale(0.8) translateY(50%) !important;
  right: -10px !important;
}
.base-slider-pc {
  position: fixed;
  right: 0;
  bottom: calc(50vh);
  width: 70px;
  z-index: 600;
  background-color: #070b11;
  min-height: 200px;
  padding: 20px 0;
  transition: all 200ms ease-in-out;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transform: scale(1.1) translateY(50%);
  box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
    0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  .switch {
    background-color: #070b11;
    height: 70px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(calc(-50% + 35px));
    text-align: center;
    i {
      color: #fff;
      font-size: 25px;
    }
  }

  .lucky-money_container {
    .lucky-money {
      width: 19.39px;
      height: 21.55px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/hongbao.webp");
    }
    &:hover {
      .lucky-money {
        background-image: url("../../assets/img/hongbao1.png");
      }
    }
  }
  .chat-group_container {
    .chat-group {
      width: 19.39px;
      height: 21.55px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/qunliao.webp");
    }
    &:hover {
      .chat-group {
        background-image: url("../../assets/img/qunliao1.png");
      }
    }
  }
  .back-top_container {
    margin-bottom: 0 !important;
    .back-top {
      width: 22px;
      height: 22px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/zhiding.webp");
    }
    &:hover {
      .back-top {
        background-image: url("../../assets/img/zhiding1.png") !important;
      }
    }
  }

  .helper_container {
    .helper {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/chongzhi.png");
    }
    &:hover {
      .helper {
        background-image: url("../../assets/img/chongzhi1.png");
      }
    }
  }

  .back_container {
    .back {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/quhui.png");
    }
    &:hover {
      .back {
        background-image: url("../../assets/img/quhui1.png");
      }
    }
  }

  .new_container {
    .new {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/baoxiang.png");
    }
    &:hover {
      .new {
        // background-image: url("../../../assets/img/index/2dfc3aa9-6317-4f50-9672-d6e956884dbb.png");
      }
    }
  }

  .item {
    color: #fff;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
    span {
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
  