<template>
    <div class="openbox">
        <!-- <myslide></myslide> -->
        <video src="../assets/audio/state-default.mp4" v-if="playstate" autoplay loop muted ref="myVideo"
            class="video"></video>
        <video src="../assets/audio/state-default1.mp4" v-if="!playstate" autoplay muted ref="myVideo1"
            class="video"></video>
        <audio ref="audioPlayer" src="../assets/audio/init.wav" type="audio/wav" autoplay muted></audio>
        <div class="kai-box">
            <!-- 开盒子 -->
            <!-- 单个盒子打开动画 v-if="openBoxState && kaiBox == 1" -->

            <div class="kai-box-open2" v-if="openBoxState && kaiBox == 1">
                <div class="kai-warp">
                    <div class="kaibox-line"></div>
                    <ul>
                        <li v-for="(item, index) in imgList" :key="index" :style="{
                            backgroundImage: 'url(' + item.background + ')'
                        }">
                            <img :src="item.img" />
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 开盒子 -->
            <!-- 多个个盒子打开动画  v-if="openBoxState && kaiBox != 1" -->
            <div :class="openPhone ? 'kai-box-open3' : 'kai-box-open4'" v-if="openBoxState && kaiBox != 1">
                <div class="kai-warp1">
                    <div class="kaibox-line"></div>
                    <div :class="{
                        'kaibox-warp kaibox-warp2 ': kaiBox == 2,
                        'kaibox-warp kaibox-warp3': kaiBox == 3,
                        'kaibox-warp kaibox-warp4': kaiBox == 4,
                        'kaibox-warp kaibox-warp5': kaiBox == 5
                    }">
                        <ul class="kaibox-ul">
                            <li class="kaibox-li" v-for="(item, index) in imgList1" :key="index" :style="{
                                animation: 'run-li' + index + ' 10s'
                            }">
                                <ul>
                                    <li v-for="(item1, index1) in item" :key="index1" :style="{
                                        backgroundImage:
                                            'url(' + item1.background + ')'
                                    }" :data="item1.name">
                                        <img :src="item1.img" :class="{
                                            'kaibox-img2': kaiBox == 2,
                                            'kaibox-img3': kaiBox == 3,
                                            'kaibox-img4': kaiBox == 4,
                                            'kaibox-img5': kaiBox == 5
                                        }" />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!--中奖完成后盒子-->
            <div class="win-box" v-if="winState">
                <mywin :winList="winList" :winState="winState" @winexchange="winexchange($event)"
                    @winget="winget($event)" @winX="winX($event)"></mywin>
            </div>

            <div class="clear"></div>
            <div class="kai-con" style="position: relative;">
                <div class="con-name">
                    <span style="padding-bottom: 5px">{{ box_name }}</span>
                    <!-- <span style="border-bottom:4px solid #02c1c3;;width:40px;"></span> -->
                </div>
                <div class="con-list">
                    <ul>
                        <!-- <li style="display:flex;justify-content: center;align-items: center;">
              <img src="../assets/img/zuo_img.png" alt="" style="width:50%">
            </li> -->
                        <li>
                            <div class="conlist-warp">
                                <div class="conlist-box">
                                    <!-- <img :src="box_obj.img_main" /> -->
                                </div>
                                <div class="conlist-box1">
                                    <img :src="box_obj.img_active" />
                                </div>
                            </div>
                        </li>
                        <!-- <li style="display:flex;justify-content: center;align-items: center;">
              <img src="../assets/img/you_img.png" alt="" style="width:50%">
            </li> -->
                    </ul>
                </div>
                <div class="con-num">
                    <ul>
                        <li v-for="(item, index) in kaiBoxNum" :key="index" :class="item.state ? 'con-num-check' : ''"
                            @click="chooseNum(item.num)">
                            <span>x{{ item.num }}</span>
                        </li>
                        <!-- <li :class="kaiBoxNum[kaiBoxNum.length - 1].state
                            ? 'con-num-check'
                            : ''
                            " @click="
                                chooseNum(kaiBoxNum[kaiBoxNum.length - 1].num)
                                ">
                            <span>x{{
                                kaiBoxNum[kaiBoxNum.length - 1].num
                            }}</span>
                        </li> -->
                    </ul>
                </div>
                <div class="con-btn">
                    <el-button class="con-btn2" @click="buyBox()" :disabled="loading">
                        <div class="con-btn1">
                            <img src="../assets/img/bg2.png" />
                            <span>{{
                                (price * kaiBox).toFixed(2)
                                }}</span>
                        </div>
                    </el-button>
                </div>
                <img class="bofang" @click="cartState = true" v-if="!cartState" src="../assets/img/bofang.png" alt="" />
                <img class="bofang" @click="cartState = false" v-else src="../assets/img/bofang1.png" alt="" />
            </div>
            <div class="kai-num">
                <span></span>
                <!-- <span>已开 5602</span> -->
            </div>
        </div>

        <div class="box-list">
            <div class="boxlist-top">
                <div class="left">
                    <div>
                        <span :class="winBoxState ? '' : 'action'" @click="getBoxOrn">包含物品</span>
                        <span :class="winBoxState ? 'action' : ''" @click="getWinPeo">最近掉落</span>
                    </div>
                    <div class="gailv" v-if="false">
                        <img src="@/assets/img/shizhong.png" alt="" />
                        <p>查看品质概率</p>
                        <div class="gailv-warp">
                            <div class="right">
                                <div class="right-one">
                                    <div class="right-data" v-if="value">
                                        <div v-for="(item, index) in skins_types" :key="index">
                                            <div class="percent">
                                                <span style="margin-right: 5px" :style="{
                                                    backgroundColor:
                                                        item.color
                                                }"></span>
                                                <h6 :style="{
                                                    color: item.color
                                                }">
                                                    {{
                                                        item.skins_type_probability
                                                    }}%
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="switch-name">掉落概率</div> -->
                                    <!-- <div class="switch1 switch2">
              <el-switch
                v-model="value"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div> -->
                                    <!-- <div class="right-two">
              <div class="switch-name">跳过动画</div>
              <div class="switch1" style="margin-left: 10px">
                <el-switch
                  v-model="cartState"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
            </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right">
                    <!-- <div class="right-one">
            <div class="right-data" v-if="value">
              <div v-for="(item, index) in skins_types" :key="index">
                <div class="percent">
                  <span
                    style="margin-right: 5px"
                    :style="{
                      backgroundColor: item.color,
                    }"
                  ></span>
                  <h6
                    :style="{
                      color: item.color,
                    }"
                  >
                    {{ item.skins_type_probability }}%
                  </h6>
                </div>
              </div>
            </div>
            <div class="switch-name">掉落概率</div> -->
                    <!-- <div class="switch1 switch2">
              <el-switch
                v-model="value"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div> -->
                    <!-- <div class="right-two">
              <div class="switch-name">跳过动画</div>
              <div class="switch1" style="margin-left: 10px">
                <el-switch
                  v-model="cartState"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
            </div> -->
                    <!-- </div> -->
                </div>
            </div>

            <div class="boxlist-bot" v-if="!winBoxState">
                <ul>
                    <li v-for="(item, index) in skin_list" :key="index">
                        <div class="boxlist-warp">
                            <div class="boxlist1-top" :style="{
                                backgroundImage:
                                    'url(' + item.background + ')'
                            }">
                                <p>{{ item.exteriorName }}</p>
                                <img :src="item.img" style="margin-top: 10%" />
                                <div class="con-btn1">
                                    <img src="../assets/img/money.png" /><span>{{ item.price }}</span>
                                </div>
                                <div class="boxlist1-bot" :title="item.name">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="clear"></div>

            <!-- <div class="boxlist-bot" v-if="winBoxState">
                <ul>
                    <li v-for="(item, index) in skin_list1" :key="index">
                        <div class="boxlist-warp">
                            <div class="boxlist1-top" :style="{
                                backgroundImage: 'url(' + item.img + ')'
                            }">
                                <img :src="item.imageUrl" />
                            </div>
                            <div class="boxlist1-bot1">
                                <img :src="item.player_img" />
                                <span>****</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="clear"></div> -->
            <div class="boxlist-botm" v-if="winBoxState">
                <div v-if="winBoxState" class="filter-user-type">
                    <!-- <span :class="{ active_type: active_type == 0 }" @click="
                        active_type = 0;
                    getWinPeo();
                    ">
                        会员</span> -->
                    <span :class="{ active_type: active_type == 0 }">
                        会员</span>
                    <!-- <span :class="{ active_type: active_type == 1 }" @click="
                        active_type = 1;
                    getWinPeo();
                    ">主播</span> -->
                </div>
                <div class="fliter-tab">
                    <p>用户名</p>
                    <p>获得饰品详情</p>
                </div>
                <div class="drop-item" :style="{ background: index % 2 ? '#20222a' : '' }"
                    v-for="(item, index) in skin_list1" :key="index">
                    <span class="user">
                        <img :src="item.player_img" />
                        <span>{{ item.player_name }}</span>
                        <img v-if="item.image" class="border-image" :src="item.image" />
                    </span>
                    <div>
                        <img :src="item.imageUrl" style="width: 80px; margin-right: 15px" />
                        <span :style="{ color: item.color }">{{
                            item.skin_name
                            }}</span>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>

        <!-- 分享-->
        <el-dialog title="分享链接" :visible.sync="dialogFormVisible" width="200px" class="share-hide">
            <div class="share-btn">
                <el-input v-model="url" autocomplete="off"></el-input>
                <el-button class="btn" type="warning" @click="copyUrl()">复制</el-button>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>

        <audio controls ref="notify4" class="audio" loop="loop">
            <source src="../assets/audio/cs22.mp3" />
        </audio>

        <audio controls ref="notify" class="audio">
            <source src="../assets/audio/open_box2.mp3" />
        </audio>

        <audio controls ref="notify1" class="audio" loop="loop">
            <source src="../assets/audio/open_box1.mp3" />
        </audio>
    </div>
</template>

<script>
import myslide from '@/components/my_slide1.vue'
import mywin from '@/components/my_win.vue'
import Utils from './../assets/js/util.js'
export default {
    components: { myslide, mywin },
    data() {
        return {
            openPhone: true,
            img: '',
            img1: require('../assets/img/1mdpi.png'),
            cartState: false,
            skin_list1: [],
            winBoxState: false,
            loading: false,
            url: window.location.href,
            dialogFormVisible: false,
            funState: true,
            winState: false,
            openBoxState: false,
            box_id: this.$route.query.box_id,
            box_name: '',
            box_obj: {},
            price: 0,
            totalPrice: 0,
            skin_list: [],
            skins_types: [],
            value: true,
            kaiBox: 1,
            kaiBoxNum: [
                { num: 1, state: true },
                { num: 2, state: false },
                { num: 3, state: false },
                { num: 4, state: false },
                { num: 5, state: false }
            ],
            winList: [],
            listBox: [],
            imgList: [],
            imgList1: [],
            playstate: true
        }
    },
    watch: {
        kaiBox(val) {
            let _this = this
            _this.totalPrice = (this.price * val).toFixed(2)
        },
        openBoxState(val) {
            if (val && this.kaiBox == 1) {
                this.playPause1()
                this.$refs.notify4.play()
            }
        }
    },
    methods: {
        playAudio() {
            let audio = this.$refs.audioPlayer
            audio.muted = true
            setTimeout(() => {
                if (audio) {
                    audio
                        .play()
                        .then(() => {
                            console.log('音频自动播放成功')
                        })
                        .catch((error) => {
                            console.warn('浏览器阻止了音频自动播放:', error)
                        })
                }
            }, 500) // 延迟播放，提升体验
        },
        playPause() {
            const video = this.$refs.myVideo
            video.play()
        },

        playPause1() {
            this.playstate = false
            const video1 = this.$refs.myVideo1
            video1.play()
            // const video = this.$refs.myVideo1
            // // video.loop = false;
            // video.play()
        },
        playclose() {
            this.playstate = true
            const video = this.$refs.myVideo1
            video.pause()
        },
        //请求背景图片
        getBack() {
            let _this = this
            _this.$axios.post('/index/Setting/background').then((res) => {
                if (res.data.status == 1) {
                    // this.img = res.data.data.img;
                    if (!this.img) {
                        // this.img = this.img1;
                    }
                }
            })
        },
        //音乐 结果
        playAlarm() {
            this.$refs.notify4.pause()
            this.$refs.notify.play()
        },
        //音乐 过程
        playAlarm1() {
            this.$refs.notify1.play()
        },
        //音乐 过程暂停
        playAlarm2() {
            this.$refs.notify1.pause()
        },

        //点击包含以下皮肤
        getBoxOrn() {
            this.winBoxState = false
            //this.getBoxInfo();
            let _this = this
            let param = {
                box_id: _this.box_id
            }
            _this.$axios
                .post('/index/Box/boxInfo', _this.$qs.stringify(param))
                .then((res) => {
                    if (res.data) {
                        _this.skin_list = res.data.data.box_skins
                    }
                })
        },
        //最近掉落
        getWinPeo() {
            this.winBoxState = true
            let param = {
                page: 1,
                pageSize: 32,
                box_id: this.box_id
            }
            this.$axios
                .post('/index/Box/lately', this.$qs.stringify(param))
                .then((res) => {
                    let data = res.data
                    // console.log(data);
                    if (data.status == 1) {
                        this.skin_list1 = data.data.list
                    }
                })
        },
        //分享复制
        copyUrl() {
            var input = document.createElement('input') // js创建一个input输入框
            input.value = window.location.href // 将需要复制的文本赋值到创建的input输入框中
            document.body.appendChild(input)
            input.select()
            document.execCommand('Copy')
            document.body.removeChild(input)
            this.$message({
                message: '复制成功',
                type: 'success'
            })
            this.dialogFormVisible = false
        },
        //盲盒信息
        getBoxInfo() {
            let _this = this
            let param = {
                box_id: _this.box_id
            }
            _this.$axios
                .post('/index/Box/boxInfo', _this.$qs.stringify(param))
                .then((res) => {
                    if (res.data) {
                        //  console.log(res.data);
                        this.box_obj = res.data.data
                        _this.box_name = res.data.data.name
                        _this.price = _this.totalPrice = res.data.data.price
                        _this.skin_list = res.data.data.box_skins
                        _this.skins_types = res.data.data.skins_types
                        this.playAudio()
                    }
                })
        },
        //购买盲盒
        buyBox() {
            this.loading = true
            let _this = this
            let param = {
                box_id: _this.box_id,
                num: _this.kaiBox,
                player_id: this.$store.state.id,
                cartState: this.cartState
            }
            
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            if (!userInfo) {
                this.$store.commit('getLogin', true)
                return
            }
            var money = userInfo.total_amount
            if (money <= 0) {
                this.$message({ message: '当前余额不足', type: 'warning' })
                this.loading = false
                return
            }
            if (!this.cartState) {
                this.$refs.notify4.play()
            }
            _this.$axios
                .post('/index/Box/buyBox', _this.$qs.stringify(param))
                .then((res) => {
                    if (res.data.status == 1) {
                        // _this.$refs.notify4.play()
                        //console.log(res.data);
                        this.$store.commit(
                            'getMoney',
                            Number(res.data.data.total_amount).toFixed(2)
                        )
                        // console.log(res.data.data.total_amount);
                        Utils.$emit('money', res.data.data.total_amount)
                        //let player_box_skin_id = res.data.data.player_box_skin_id;
                        let imgCopy = JSON.parse(JSON.stringify(this.skin_list))
                        if (this.kaiBox == 1) {
                            let imgCopy1 = []
                            for (
                                let i = 0;
                                i < Math.floor(80 / imgCopy.length) + 1;
                                i++
                            ) {
                                for (let j = 0; j < imgCopy.length; j++) {
                                    imgCopy1.push(imgCopy[j])
                                }
                            }
                            imgCopy1 = imgCopy1.slice(0, 80)
                            imgCopy1 = this.getRandomArr(imgCopy1, 80)
                            this.imgList = imgCopy1
                            // console.log(this.imgList.length);
                        } else {
                            let imgCopy2 = []
                            for (let i = 0; i < this.kaiBox; i++) {
                                this.imgList1.push(imgCopy)
                            }
                            for (
                                let i = 0;
                                i <
                                Math.floor(60 / this.imgList1[0].length) + 1;
                                i++
                            ) {
                                for (
                                    let j = 0;
                                    j < this.imgList1[0].length;
                                    j++
                                ) {
                                    imgCopy2.push(this.imgList1[0][j])
                                }
                            }
                            imgCopy2 = imgCopy2.slice(0, 60)
                            for (let i = 0; i < this.imgList1.length; i++) {
                                this.imgList1[i] = imgCopy2
                                this.imgList1[i] = this.getRandomArr(
                                    this.imgList1[i],
                                    60
                                )
                            }
                        }

                        //开盲盒
                        //_this.getBoxResult(player_box_skin_id);
                        this.loading = false
                        if (res.data.data.skins_info.length == 1) {
                            this.imgList[62] = res.data.data.skins_info[0]
                            _this.setStyle(4)
                        } else {
                            let data = res.data.data.skins_info
                            let imgListCopy = JSON.parse(
                                JSON.stringify(this.imgList1)
                            )

                            for (let i = 0; i < data.length; i++) {
                                imgListCopy[i][50] = data[i]
                            }
                            this.imgList1 = imgListCopy
                        }
                        _this.winList = res.data.data

                        //是否开启动画
                        if (this.cartState) {
                            _this.winState = true
                            this.playAlarm()
                            this.playstate = true
                        } else {
                            _this.openBoxState = true
                            var length = res.data.data.skins_info.length
                            _this.setStyle('', length)
                            setTimeout(() => {
                                _this.openBoxState = false
                                this.playstate = true
                                _this.winState = true
                                this.imgList = []
                                this.imgList1 = []
                                this.playAlarm()
                            }, 12000 + 200 * length)
                        }
                    } else {
                        this.$refs.notify4.pause()
                        this.loading = false
                        let msg = res.data.msg
                        if (msg.indexOf('当前余额不足') != -1) {
                            this.$message({ message: msg, type: 'warning' })
                            return
                        }
                        if (msg == '玩家信息错误') {
                            this.$message({
                                message: '请先登录',
                                type: 'warning'
                            })
                            this.$store.commit('getLogin', true)
                            return
                        }
                        if (msg == '购买失败，请联系客服') {
                            this.$message('购买失败，请联系客服处理')
                        }
                    }
                })
        },
        // 打乱数组
        getRandomArr(arr, num) {
            var _arr = arr.concat()
            var n = _arr.length
            var result = []

            // 先打乱数组
            while (n-- && num--) {
                var index = Math.floor(Math.random() * n) // 随机位置
                    ;[_arr[index], _arr[n]] = [_arr[n], _arr[index]] // 交换数据
                result.push(_arr[n]) // 取出当前最后的值，即刚才交换过来的值
            }
            return result
        },
        chooseNum(num) {
            this.kaiBox = num
            for (let i = 0; i < this.kaiBoxNum.length; i++) {
                if (this.kaiBoxNum[i].num == num) {
                    this.kaiBoxNum[i].state = true
                } else {
                    this.kaiBoxNum[i].state = false
                }
            }
        },
        //子组件修改父组件
        winexchange(msg) {
            this.winState = msg
        },
        winget(msg) {
            this.winState = msg
        },
        winX(msg) {
            this.winState = msg
        },
        setStyle(index, num) {
            let style = document.createElement('style')
            style.setAttribute('type', 'text/css')
            document.head.appendChild(style)
            let sheet = style.sheet
            if (index == 4) {
                let random = Math.floor(Math.random() * 190) + 11905
                // console.log(random);
                sheet.insertRule(
                    `@keyframes run` +
                    index +
                    `{
            0% {
              left: 0;
            }
            100% {
              left: -` +
                    random +
                    `px
            }
        }`,
                    0
                )
            } else {
                for (var i = 0; i < num; i++) {
                    if (this.openPhone == true) {
                        var random = Math.floor(Math.random() * 190) + 9905
                    } else {
                        //屏幕小于600的宽度
                        var random = Math.floor(Math.random() * 90) + 5955
                    }
                    sheet.insertRule(
                        `@keyframes run-li` +
                        i +
                        `{
              0% {
                top: 0;
              }
              100% {
                top: -` +
                        random +
                        `px
              }
          }`,
                        0
                    )
                }

                // this.imgList1.forEach((element, index) => {
                //   if(this.openPhone == true){
                //     var random = Math.floor(Math.random()*190)+9905;
                //   }else{
                //     //屏幕小于600的宽度
                //     var random = Math.floor(Math.random()*190)+5905;
                //   }
                //   let style = document.createElement('style');
                //   style.setAttribute('type', 'text/css');
                //   document.head.appendChild(style);
                //   let sheet = style.sheet;
                //   //根据每一个时钟的数据为页面添加不同的keyframes
                //   var time = 1;
                //   var timer = setInterval(() => {
                //     if(time>num){
                //       clearInterval(timer)
                //     }
                //     console.log(time);
                //     time++
                //     sheet.insertRule(
                //       `@keyframes run-li` +(index) +`{
                //         0% {
                //           top: 0;
                //         }
                //         100% {
                //           top: -`+random+`px
                //         }
                //     }`,0);
                //   }, 200);
                // });
            }
        }
    },
    mounted() {
        this.playPause()
        //判断屏幕宽度
        // console.log(document.body.clientWidth);
        if (document.body.clientWidth < 600) {
            this.openPhone = false
        }

        let _this = this
        _this.getBoxInfo()
        _this.getBack()
        // _this.setStyle(5,5)
    }
}
</script>

<style lang="less" scoped>
.video {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    // z-index:9;
}

.bofang {
    position: absolute;
    bottom: 0;
    left: 60%;
    cursor: pointer;
}

.openbox {
    // overflow: hidden;
    // overflow-y: scroll;
    // background: url("../assets/img/1mdpi.png");

    width: 100%;
    height: 100%;
    position: relative;

    .switch-name {
        color: #19191a;
        font-size: 16px;
        // margin-right: 5px;
    }

    .switch2 {
        margin: 0 10px;
    }

    .audio {
        display: none;
    }

    .kai-box {
        position: relative;
        padding: 16px;

        .kai-share {
            float: right;
            color: #848492;
            font-size: 15px;
            font-weight: 600;
            padding: 9px 22px;
            background-color: #fff;
            border-radius: 5px;
        }

        .kai-share:hover {
            background-color: #ddd;
            cursor: pointer;
        }
    }

    //测试开盒子 单个
    .kai-box-open2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999999999;

        .kai-warp {
            position: absolute;
            width: 1000px;
            height: 150px;
            top: 30%;
            left: 50%;
            margin-left: -500px;
            overflow: hidden;
            background-color: #fff;

            .kaibox-line {
                position: absolute;
                width: 2px;
                height: 150px;
                background-color: #e9b10e;
                left: 50%;
                top: 0;
                z-index: 99;
            }

            ul {
                position: absolute;
                left: 0;
                top: 0;
                width: 16000px;
                display: flex;
                flex-wrap: nowrap;
                z-index: 20;
                animation: run4 10s;
                animation-timing-function: ease; //动画慢 快 慢
                animation-iteration-count: 1; //播放几次动画
                animation-delay: 0s; //动画运行前等待时间
                animation-fill-mode: forwards; //动画结束 是否保持

                li {
                    width: 200px;
                    height: 150px;
                    background-image: url('../assets/img/box-skins-null.jpg');
                    background-size: 100% 100%;

                    img {
                        margin-left: 10%;
                        margin-top: 10%;
                        width: 80%;
                        height: 80%;
                    }
                }

                .li1 {
                    background-image: url('../assets/img/box-skins-golden.jpg');
                }

                .li2 {
                    background-image: url('../assets/img/box-skins-violet.jpg');
                }
            }

            // @keyframes run4 {
            //   0% {
            //     left: 0;
            //   }
            //   100% {
            //     left: -12000px;
            //   }
            // }
        }
    }

    //测试开多个盒子
    .kai-box-open3 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 66;

        .kai-warp1 {
            position: absolute;
            width: 100%;
            height: 200px;
            top: 30%;
            left: 0;
            display: flex;
            justify-content: center;

            .kaibox-line {
                width: 100%;
                height: 2px;
                position: absolute;
                top: 99px;
                left: 0;
                z-index: 99;
                background-color: #e9b10e;
            }

            .kaibox-warp {
                overflow: hidden;
                width: 100%;

                .kaibox-ul {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    position: relative;

                    .kaibox-li {
                        //overflow: hidden;
                        width: 15%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        animation: run5 10s;
                        animation-timing-function: ease !important; //动画慢 快 慢
                        animation-iteration-count: 1 !important; //播放几次动画
                        animation-delay: 0s; //动画运行前等待时间
                        animation-fill-mode: forwards !important; //动画结束 是否保持

                        // animation-timing-function: ease;
                        // animation-iteration-count: 1;
                        // animation-fill-mode: forwards;
                        ul {
                            li {
                                width: 100%;
                                height: 200px;
                                background-image: url('../assets/img/box-skins-null.jpg');
                                background-size: 100% 100%;

                                // border: 1px solid #fff;
                                img {
                                    margin-left: 2%;
                                    margin-top: 10px;
                                    width: 95%;
                                    max-height: 180px;
                                }
                            }

                            .li1 {
                                background-image: url('../assets/img/box-skins-golden.jpg');
                            }

                            .li2 {
                                background-image: url('../assets/img/box-skins-violet.jpg');
                            }
                        }
                    }
                }
            }

            .kaibox-warp2 {

                //width: 600px;
                .kaibox-ul {
                    .kaibox-li:nth-child(1) {
                        left: 35%;
                        animation-delay: 0.25s !important;
                    }

                    .kaibox-li:nth-child(2) {
                        left: 49.5%;
                        animation-delay: 0.5s !important;
                    }
                }
            }

            .kaibox-warp3 {

                //width: 900px;
                .kaibox-ul {
                    .kaibox-li:nth-child(1) {
                        left: 27.5%;
                        animation-delay: 0.15s !important;
                    }

                    .kaibox-li:nth-child(2) {
                        left: 42%;
                        animation-delay: 0.3s !important;
                    }

                    .kaibox-li:nth-child(3) {
                        left: 57%;
                        animation-delay: 0.45s !important;
                    }
                }
            }

            .kaibox-warp4 {

                //width: 1200px;
                .kaibox-ul {
                    .kaibox-li:nth-child(1) {
                        left: 20%;
                        animation-delay: 0.15s !important;
                    }

                    .kaibox-li:nth-child(2) {
                        left: 34.5%;
                        animation-delay: 0.3s !important;
                    }

                    .kaibox-li:nth-child(3) {
                        left: 49%;
                        animation-delay: 0.45s !important;
                    }

                    .kaibox-li:nth-child(4) {
                        left: 63.5%;
                        animation-delay: 0.6s !important;
                    }
                }
            }

            .kaibox-warp5 {

                //width: 1500px;
                .kaibox-ul {
                    .kaibox-li:nth-child(1) {
                        left: 12.5%;
                        animation-delay: 0.15s !important;
                    }

                    .kaibox-li:nth-child(2) {
                        left: 27%;
                        animation-delay: 0.3s !important;
                    }

                    .kaibox-li:nth-child(3) {
                        left: 41.5%;
                        animation-delay: 0.45s !important;
                    }

                    .kaibox-li:nth-child(4) {
                        left: 56%;
                        animation-delay: 0.6s !important;
                    }

                    .kaibox-li:nth-child(5) {
                        left: 70.5%;
                        animation-delay: 0.75s !important;
                    }
                }
            }

            // @keyframes run5 {
            //   0% {
            //     top: 0;
            //   }
            //   100% {
            //     //50
            //     top: -10000px;
            //   }
            // }
        }
    }

    .kai-box-open4 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 66;

        .kai-warp1 {
            position: absolute;
            width: 100%;
            height: 120px;
            top: 30%;
            left: 0;
            display: flex;
            justify-content: center;

            .kaibox-line {
                width: 100%;
                height: 2px;
                position: absolute;
                top: 59px;
                left: 0;
                z-index: 99;
                background-color: #e9b10e;
            }

            .kaibox-warp {
                overflow: hidden;
                width: 100%;

                .kaibox-ul {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    position: relative;

                    .kaibox-li {
                        //overflow: hidden;
                        width: 20%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        animation: run6 10s;
                        animation-timing-function: ease !important; //动画慢 快 慢
                        animation-iteration-count: 1 !important; //播放几次动画
                        animation-delay: 0s; //动画运行前等待时间
                        animation-fill-mode: forwards !important; //动画结束 是否保持

                        ul {
                            li {
                                width: 100%;
                                height: 120px;
                                background-image: url('../assets/img/box-skins-null.jpg');
                                background-size: 100% 100%;

                                // border: 1px solid #fff;
                                img {
                                    // margin-left: 2%;
                                    margin-top: 10px;
                                    width: 95%;
                                    max-height: 100px;
                                }
                            }

                            .li1 {
                                background-image: url('../assets/img/box-skins-golden.jpg');
                            }

                            .li2 {
                                background-image: url('../assets/img/box-skins-violet.jpg');
                            }
                        }
                    }
                }
            }

            .kaibox-warp2 {

                //width: 600px;
                .kaibox-ul {
                    .kaibox-li:nth-child(1) {
                        left: 30%;
                        animation-delay: 0.25s !important;
                    }

                    .kaibox-li:nth-child(2) {
                        left: 49.5%;
                        animation-delay: 0.5s !important;
                    }
                }
            }

            .kaibox-warp3 {

                //width: 900px;
                .kaibox-ul {
                    .kaibox-li:nth-child(1) {
                        left: 20%;
                        animation-delay: 0.15s !important;
                    }

                    .kaibox-li:nth-child(2) {
                        left: 39.5%;
                        animation-delay: 0.3s !important;
                    }

                    .kaibox-li:nth-child(3) {
                        left: 59%;
                        animation-delay: 0.45s !important;
                    }
                }
            }

            .kaibox-warp4 {

                //width: 1200px;
                .kaibox-ul {
                    .kaibox-li:nth-child(1) {
                        left: 10%;
                        animation-delay: 0.15s !important;
                    }

                    .kaibox-li:nth-child(2) {
                        left: 29.5%;
                        animation-delay: 0.3s !important;
                    }

                    .kaibox-li:nth-child(3) {
                        left: 49%;
                        animation-delay: 0.45s !important;
                    }

                    .kaibox-li:nth-child(4) {
                        left: 68.5%;
                        animation-delay: 0.6s !important;
                    }
                }
            }

            .kaibox-warp5 {

                //width: 1500px;
                .kaibox-ul {
                    .kaibox-li:nth-child(1) {
                        left: 0;
                        animation-delay: 0.15s !important;
                    }

                    .kaibox-li:nth-child(2) {
                        left: 19.9%;
                        animation-delay: 0.3s !important;
                    }

                    .kaibox-li:nth-child(3) {
                        left: 39.8%;
                        animation-delay: 0.45s !important;
                    }

                    .kaibox-li:nth-child(4) {
                        left: 59.7%;
                        animation-delay: 0.6s !important;
                    }

                    .kaibox-li:nth-child(5) {
                        left: 79.6%;
                        animation-delay: 0.75s !important;
                    }
                }
            }

            // @keyframes run6 {
            //   0% {
            //     top: 0;
            //   }
            //   100% {
            //     //50
            //     top: -6000px;
            //   }
            // }
        }
    }

    //中奖后盒子
    .win-box {
        position: absolute;
        z-index: 99;
        top: 5%;
        left: 50%;
        margin-left: -225px;
    }

    .kai-con {
        display: flex;
        flex-direction: column;
        align-items: center;

        .con-name {
            font-family: 'Compressed';
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            color: white;
            // background: #ffc400;
            display: inline-block;
            width: 200px;
            padding: 10px 0;
            text-align: center;
            font-weight: 700;
            // margin-top:100px;
        }

        .con-list {
            margin-top: 20px;
            width: 100%;

            ul {
                width: 100%;
                display: flex;
                justify-content: center;

                li {
                    width: 20%;
                    max-width: 300px;

                    .conlist-warp {
                        // border-radius: 50%;
                        position: relative;
                        // background-color: rgba(65, 105, 161, 0.2);
                        padding: 10% 0;
                        margin: 0 5px;
                        text-align: center;
                        display: flex;
                        justify-content: center;

                        .conlist-box {
                            width: 90%;
                            height: 250px;
                            text-align: center;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .conlist-box1 {
                            width: 90%;
                            position: absolute;
                            left: 0;
                            top: 25%;
                            // left: 5%;
                            // animation: boxhover 3s linear 0s infinite alternate;
                            animation: freeFall 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards,
                                boxhover 2s ease-in-out infinite 2s;

                            img {
                                width: 320px;
                                height: 320px;
                            }
                        }
                    }
                }

                li:hover {
                    cursor: pointer;
                }

                @keyframes freeFall {
                    0% {
                        top: 0;
                        /* 初始位置：顶部 */
                    }

                    100% {
                        top: 25%;
                        /* 快接近地面时减速 */
                    }
                }

                @keyframes boxhover {
                    0% {
                        top: 25%;
                    }

                    50% {
                        top: 15%;
                    }

                    100% {
                        top: 25%;
                    }
                }
            }
        }

        .con-num {
            margin-top: 100px;

            ul {
                display: flex;

                li {
                    width: 52px;
                    height: 52px;
                    line-height: 52px;
                    text-align: center;
                    // margin: 0 8px;
                    background: linear-gradient(357.91deg,
                            #18172e 1.14%,
                            #131228 98.23%);

                    // border: 1px solid #ffc400;
                    span {
                        font-size: 0.94vw;
                        color: white;
                        font-weight: 600;
                    }
                }

                li:hover {
                    cursor: pointer;
                    // background-color: #444659;
                    opacity: 0.8 !important;
                }

                .con-num-check {
                    // background-color: #ff9b0b;
                    background: linear-gradient(357.1deg,
                            #211b44 2.04%,
                            #251f4b 2.05%,
                            #201c43 97.96%);

                    span {
                        color: #8380ff;
                    }
                }
            }
        }

        .con-btn {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border-radius: 20px;
            // border: 1px solid #e9b10e;
            // border: 2px solid #02c1c3;
            // background-image:linear-gradient(to right,#87cde0, #c537bc);
            //background-color: #93b0d9;
            position: relative;
            background-color: #c03454;
            /* 主体背景色 */
            color: #ffffff;
            /* 文字颜色 */
            // padding: 15px 30px;
            font-size: 18px;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            border-radius: 5px;
            cursor: pointer;

            .con-btn1 {
                padding: 4px 16px;
                display: flex;
                align-items: center;

                span {
                    font-size: 15px;
                    color: white;
                    font-weight: 600;
                    margin-left: 5px;
                }

                img {
                    width: auto;
                    height: 25px;
                }
            }

            .con-btn2 {
                border: none;
                // background-color: #02c1c3;
                // background-image: linear-gradient(to right, #ff571b, #ff9b0b);
                color: white;
                // padding: 8px 16px;
                // border-radius: 20px;
                font-size: 14px;
                margin-right: -1px;
                background: none;
            }

            .con-btn2:hover {
                cursor: pointer;
            }
        }

        .con-btn::before,
        .con-btn::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 20px;
            background-color: #c03454;
            /* 伪元素背景色，和主体一致 */
        }

        .con-btn::before {
            left: -20px;
            clip-path: polygon(100% 0, 0 50%, 100% 100%);
            /* 实现左侧箭头效果 */
        }

        .con-btn::after {
            right: -20px;
            clip-path: polygon(0 0, 100% 50%, 0 100%);
            /* 实现右侧箭头效果 */
        }
    }

    .kai-num {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 16px;
            color: #848492;
            display: flex;
            align-items: center;
        }

        i {
            color: #e9b10e;
            font-size: 20px;
        }

        h6 {
            font-size: 16px;
            color: #848492;
            font-weight: 200;
        }
    }

    .box-list {
        width: 100%;
        // margin: 20px 16px 50px 16px;
        // padding: 0 16px 16px 16px;
        background-color: #141225;
        border-radius: 5px;
        // border: 1px solid #ddd;
        position: absolute;

        .boxlist-top {
            width: 95%;
            margin: auto;
            padding: 0 16px 0 0;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #141225;
            margin-top: 30px;

            .left {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 30px 0;

                >div {
                    width: 100%;
                    border-bottom: 3px solid #ffc400;

                    >.action {
                        background: #ffc400;
                        color: black;
                        font-weight: bold;
                    }

                    >span {
                        font-size: 16px;
                        color: #fff;
                        display: inline-block;
                        height: 50px;
                        line-height: 50px;
                        padding: 0 16px;
                        // background:#ffc400;
                        border-top: 3px solid #ffc400;
                        border-left: 3px solid #ffc400;
                        border-right: 3px solid #ffc400;
                    }
                }

                .gailv:hover .gailv-warp {
                    display: block;
                }

                >.gailv {
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;

                    >p {
                        font-size: 14px;
                        color: #6f6d88;
                        margin-left: 5px;
                    }

                    >img {
                        width: 18px;
                        height: 18px;
                    }

                    .gailv-warp {
                        height: 50px !important;
                        position: absolute;
                        background: #19172d;
                        padding: 0 10px;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        left: 110%;
                        bottom: 20%;
                        display: none;
                    }
                }

                .span1 {
                    // background-color: #2b2c37;
                }

                span:hover {
                    cursor: pointer;
                }
            }

            .right {
                display: flex;
                align-items: center;
                height: 50px;

                .right-one,
                .right-two {
                    display: flex;
                }

                .right-data {
                    display: flex;
                    align-items: center;

                    .percent {
                        margin-right: 15px;
                        display: flex;
                        align-items: center;

                        span {
                            display: table;
                            width: 7px;
                            height: 7px;
                            border-radius: 5px;
                            padding: 0 !important;
                        }

                        .span1 {
                            background-color: #f1a920;
                        }

                        .span11 {
                            color: #f1a920;
                        }

                        .span2 {
                            background-color: #b969d4;
                        }

                        .span12 {
                            color: #b969d4;
                        }

                        .span3 {
                            background-color: #adc8cd;
                        }

                        .span13 {
                            color: #adc8cd;
                        }
                    }
                }
            }
        }

        .boxlist-bot {
            // padding: 0 16px;
            width: 95%;
            margin: 30px auto;

            ul {
                margin: 0 -8px;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                flex-wrap: wrap;
                gap: 15px;

                li:hover .boxlist1-bot {
                    color: white !important;
                }

                li {
                    width: 176px;
                    height: 240px;
                    float: left;
                    background: #19172d;
                    margin-bottom: 15px;
                    cursor: pointer;

                    .boxlist-warp {
                        // background-color: #24252f;
                        // border: 1px solid #ddd;
                        border-radius: 5px;

                        .con-btn1 {
                            padding: 0 8px 0 8px;
                            display: flex;
                            align-items: center;

                            // background-color: #ddd;
                            span {
                                color: white;
                                font-size: 14px;
                                font-weight: 600;
                                margin-left: 5px;
                            }

                            img {
                                width: 10px;
                                height: 10px;
                            }
                        }

                        .boxlist1-top {
                            height: 240px;
                            padding: 0 20px;
                            background-size: 100% 100%;
                            text-align: center;
                            // border-bottom: 3px solid #f1a920;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            // box-shadow: 0px 5px 10px #f1a920;
                            position: relative;

                            >p {
                                color: #f4bd50;
                                font-size: 14px;
                                padding: 20px 0;
                            }

                            .huo {
                                width: 18px;
                                height: 18px;
                                position: absolute;
                                top: 5px;
                                right: 5px;
                                z-index: 1;
                            }

                            >img {
                                width: 90%;
                                height: 100px;
                            }
                        }

                        .boxlist1-top:before {
                            content: ' ';
                            height: 40px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: linear-gradient(0deg,
                                    rgba(209, 39, 62, 0.3) 0%,
                                    rgba(0, 0, 0, 0) 100%);
                            z-index: 1;
                        }

                        .boxlist1-top:hover:before {
                            background: linear-gradient(0deg,
                                    rgba(209, 39, 62, 0.5) 0%,
                                    rgba(0, 0, 0, 0) 100%);
                        }

                        .boxlist1-top:after {
                            content: '';
                            height: 2px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #d1273e;
                        }

                        .boxlist1-bot {
                            padding: 8px;
                            color: #9692b8;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            // background-color: rgb(25, 23, 46);
                            font-size: 12px;
                        }

                        .boxlist1-bot1 {
                            padding: 8px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                            }

                            span {
                                margin-left: 8px;
                                color: #c3c3e2;
                                font-size: 14px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }

    /* .share-hide {
    //height: 300px;
  }*/
    .share-btn {
        display: flex;

        .btn {
            margin-left: 10px;
        }
    }

    /deep/ .el-dialog__footer {
        display: none;
    }

    /deep/ .el-dialog__title {
        color: #848492;
    }

    /deep/ .el-dialog__body {
        padding: 5px 20px;
    }

    /deep/ .el-dialog {
        min-height: none;
    }
}

.boxlist-botm {
    width: 95%;
    margin: 30px auto;

    .increase-rose {
        height: 38px;
        margin-top: 20px;
        margin-bottom: 20px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        // background-image: url("../assets/img/open-box/zjdl-bg.png");

        .rose-item {
            display: flex;
            align-items: center;
            margin-right: 15px;

            .point {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }

    ul {
        width: 100%;
        display: grid;
        grid-gap: 15px;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

        li {
            width: 100%;
            background-size: 100% auto;
            background-repeat: no-repeat;
            cursor: pointer;

            .boxlist-warp {
                // background-color: #24252f;
                border-radius: 5px;
                display: flex;
                position: relative;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;

                .boxlist1-top {
                    padding: 0 20px;
                    background-size: 100% 100%;
                    text-align: center;
                    // border-bottom: 3px solid #f1a920;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;

                    // box-shadow: 0px 5px 10px #f1a920;
                    img {
                        width: 100%;
                        margin-top: 10%;
                        height: auto;
                        min-height: 100px;
                        cursor: pointer;
                    }
                }

                .boxlist1-bot {
                    padding: 8px;
                    width: 150px;
                    color: #fff;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 18px;
                    white-space: nowrap;
                    // word-wrap: break-word;
                }

                .boxlist1-bot1 {
                    padding: 8px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                    }

                    span {
                        margin-left: 8px;
                        color: #fff;
                        font-size: 18px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }

                .list-pirce {
                    padding: 0px 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .ico {
                    display: block;
                    margin: 20px 0 10px 0;
                    font-size: 18px;
                    color: #f4bd50;
                    padding: 2px 4px;
                }

                .pirce-left {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    padding-right: 10px;

                    img {
                        width: auto;
                        height: 15px;
                        margin-right: 5px;
                    }

                    &>div {
                        flex: 1;
                        color: #f4bd50;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span:last-child {
                            color: #fff;
                        }
                    }
                }

                .pirce-right {
                    color: #848492;
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.filter-user-type {
    display: flex;
    background: #ffc400;
    width: max-content;
    margin-top: 20px;

    span {
        display: block;
        padding: 5px 20px;
        color: black;
        border: 3px solid #ffc400;
        font-size: 14px;
        cursor: pointer;
    }

    .active_type {
        background-color: #ffc400;
        color: #000000;
        font-weight: bold;
    }
}

.fliter-tab {
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: 46%;
    justify-content: space-between;
}

.drop-item {
    display: flex;
    justify-content: space-between;
    // background-color: #181c28;
    align-content: center;
    padding: 15px 0;
    width: 70%;

    >div {
        display: flex;
        align-items: center;
        width: 50%;

        >span {
            font-size: 13px;
        }
    }

    .user {
        display: flex;
        padding-left: 0;
        width: 50%;
        justify-content: flex-start;
    }

    img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 3px;
    }

    span:nth-child(2) {
        color: #fff;
    }

    span {
        color: #ffc400;
        font-size: 15px;
        // padding-left: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 20vw;
        display: flex;
    }

    p {
        color: #fff;
        display: flex;
        // justify-content: center;
        align-items: center;
        width: 50%;
    }

    .user-info {
        display: flex;
        position: relative;
        width: 50%;

        span {
            color: #fff;
            padding-left: 2px;
        }
    }
}
</style>
