<template>
  <div class="bot-right-inform" v-if="$store.state.informState">
    <div class="inform-left">
      <!-- <div>
        <div class="tz">
          <img src="../assets/img/xinxi.png" />
        </div>
      </div> -->

      <div class="span">
        <span id="span"
          >不定时抽小玩具以及CDK！有事联系在线客服解决！最近骗子横行繁多，并会模仿官方客服、进行线下交易、线下转账，骗术繁多！大家擦亮眼睛，切勿贪图小便宜！上当受骗！！ 理性消费！未成年免入！网站福利注册红包：注册即可领取惊喜红包，红包自动进入余额，任意充值后可全站使用！免费ROLL房：注册并通过核验URL，即可进入无门槛ROLL房，感受饰品从天而降！特别说明：本站拥有正规备案；采用公平算法保证掉落随机；支持饰品自动发货；请放心娱乐！为了更好的良好体验，盲盒会不定期进行迭代。如有任何疑问，请联系站内客服。最终解释权归518skins所有。特别提醒：为了维护用户权益，涉嫌违规、异常等账号将会封禁具体说明：禁止交易、返现；禁止绑定他人URL；禁止同一人注册多个账号或恶意注册账号；严禁违反用户协议，以上一经发现永久封禁！盲盒娱乐，理性消费！
        </span>
      </div>
    </div>
    <!-- <div class="inform-right" @click="getInformState">
      <i class="el-icon-close"></i>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      informState: true,
      screenWidth:document.body.clientWidth,
    };
  },
  
  watch:{
    screenWidth(val){
      console.log(val);
    },
  },
  methods: {
    //取消顶部通知
    getInformState() {
      this.$store.commit("getInform", false);
    },
    getSpanWidth(){
      var span = document.getElementById('span');
      var spanWidth = span.offsetWidth;

      // let style = document.createElement('style');
      // style.setAttribute('type', 'text/css');
      // document.head.appendChild(style);
      // let sheet = style.sheet;
      // let random = Math.floor(Math.random()*190)+11905;
      // sheet.insertRule(
      //   `@keyframes run``{
      //     0% {
      //       left: 0;
      //     }
      //     100% {
      //       left: -`+random+`px
      //     }
      // }`,0);
    }
  },
  mounted(){
    const _this = this;
    window.onresize= () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        _this.screenWidth = window.screenWidth;
      })();
    };
    _this.getSpanWidth();
  }
};
</script>

<style lang="less" scoped>
.bot-right-inform {
  // background-color: #fef6e9;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .inform-left {
    max-width: 95%;
    display: flex;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
    }
    .span {
      margin-left: 10px;
      overflow: hidden;
    }
    span {
      // margin-left: 20px;
      font-size: 16px;
      color: white;
      white-space: nowrap;
      //	text-overflow: ellipsis;
      // overflow: hidden;
    }
    span {
      position: relative;
      right: 0;
      animation: marquee 30s linear infinite;
      @keyframes marquee {
        0% {
          right: 0;
        }
        100% {
          right: 1400px;
        }
      }
    }
  }
  .inform-right {
    i {
      font-size: 18px;
      color: #e9b10e;
    }
  }
  .inform-right:hover {
    cursor: pointer;
  }
  .tz{
    width: 30px;
    height: 30px;
    // background: #FF9B0B;
    // border-radius: 50%;
    position: relative;
    img{
      position:absolute;
      top: 50%;
      left: 50%;
      margin-left: -9px;
      margin-top: -9px;
    }
  }
}
</style>
